@import 'variables';
$placeholderColor: #000;
$textColor: #494952;
$bgColor: #EDEEF2;
$borderColor: #aaaab6;

.form {
  &-item {
    width: 100%;
    margin-bottom: 18px;
    box-sizing: border-box;
    position: relative;

    &--no-margin {
      margin-bottom: 0;
    }

    &_validation-error {
      margin-top: 4px;
      font-size: 0.8rem;
      color: $red;
      display: inline-block;
    }
    &_fullForm-error {
      margin-top: 4px;
      font-size: 0.8rem;
      color: $red;
      display: inline-block;
      margin-bottom: 10px;
    }
  }

  &-item_input {
    background: 0 0;
    background-color: $bgColor;
    border: 0;
    outline: 0;
    width: 100%;
    border-radius: 0;
    box-sizing: border-box;
    padding: 8px 5px;
    color: inherit;
    border-bottom: solid 2px $borderColor;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  &-link {
    &_wr {
      display: flex;
      &:last-child {
        color: $blue;
      }
      &--right {
        justify-content: flex-end;
      }

      &--left {
        justify-content: flex-start;
      }

      //.form-link_item {
      //  margin-right: 18px;
      //}

      &--center {
        text-align: center;
        justify-content: center;
      }
      &--evenly {
        justify-content: space-evenly;
      }
    }

    &_item {
      padding: 0;
      background: 0 0;
      border: 0;
      outline: 0;
      color: inherit;
      appearance: none;
      border-bottom: dashed 1px currentColor;
      transition: all 0.2s ease;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }

      &[disabled] {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }

  &-error {
    color: $red;
  }
}

button {
  outline: none;
  cursor: pointer;
}

input[type="radio"], input[type="checkbox"] {
  cursor: pointer;
}

input[type="radio"] ~ label, input[type="checkbox"] ~ label {
  cursor: pointer;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn__wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

button.sertificates__btn {
  position: relative;
  overflow: hidden;
  padding: 12px 0;
  //margin: 2rem 0;
  border-radius: 50px;
  //background-color: #ffffff;
  color: #fff;
  //border: solid 2px #e3e3e3;
  transition: ease, 0.5s;
  background-color: #d62445;
  border: solid 2px #d62445;

  &:hover {
    background-color: #e14c68;
    border-color: #e14c68;
    &::before {
      width: 100%;
    }
  }

  &:disabled {
    color: $lightGray;
    border-color: unset;
    background-color: #fff;
    cursor: unset;
    opacity: 0.7;

    &::before {
      content: none;
    }
    &:hover {
      background-color: inherit;
      border-color: inherit;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-radius: 25px;
    width: 0;
    transition: ease, 0.5s;
  }

  span {
    position: relative;
    z-index: 3;
  }
}

.sertificates__btn + .sertificates__btn {
  margin-left: 10px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.select-date-button {
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  padding: 8px 15px;
  border: solid 2px $blue;
  font-weight: 500;
  transition: ease 0.5s;
  text-align: center;
  background-color: #fff;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .8rem;
  @media screen and (max-width: 767px) {
    max-width: 200px;
  }

  @at-root .purple & {
    border-color: $purple;
  }
  @at-root .blue & {
    border-color: $blue;
  }
  @at-root .green & {
    border-color: $green;
  }
  &:active {
    opacity: .6;
    box-shadow: 2px 1000px 1px #fff inset;
  }
  &:hover {
    box-shadow: 0 0 10px;
    border-color: #02a5dd;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    border-color: $mainColor;
    &:hover {box-shadow: none;}
  }
}

.button-with-tooltip {
  position: relative;
}

.tooltip {
  position: absolute;
  bottom: calc(100% + 0.6rem);
  left: 0;
  min-width: 100px;
  height: auto;
  padding: 0.5rem;
  background-color: $blue;
  color: #fff;
  font-family: 'Comfortaa Light';
  font-size: 10px;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  border-radius: 4px;
  transition: 0.25s;

  &::after {
      content: '';
      position: absolute;
      top: 99%;
      left: 0.5rem;
      border-top: 0.6rem solid $blue;
      border-left: 0.3rem solid transparent;
      border-right: 0.3rem solid transparent;
  }

  &_success{
    flex-direction: unset;
    background: $tooltipSuccessBackground;
    padding: 13px 18px;
    //margin-bottom: 20px;
    border-radius: 4px;
    //box-shadow: 1px 1px 0 0 rgba(0,0,0,.05);
    line-height: 1.25;
    border: 1px solid $tooltipSuccessForeground;
  }
  &_error{
    padding: 13px 18px;
    border-radius: 4px;
    font-size: 16px;
    background: $tooltipErrorBackground;
    border: 1px solid $tooltipErrorForeground;
    a{
      color: $tooltipErrorForeground;
    }
  }
  &_block{
    padding: 13px 18px;
    border-radius: 4px;
    font-size: 16px;
    background: $tooltipBlockBackground;
    border: 1px solid $tooltipBlockBorder;
    a{
      color: $tooltipBlockForeground;
    }
  }
}

.select-date-button._error:hover ~ .tooltip {
  opacity: 1;
  visibility: visible;
}

.color-error {
  display: block;
  font-size: 0.7rem;
  color: $lightRed;
}
