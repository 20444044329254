.input {
    outline: none;

    &__text {
      outline: none;
      padding: 12px 16px;
      border-width: 2px;
      border-color: $gray;
      border-style: solid;
      border-radius: 10px;
      height: 48px;
      font-size: 16px;
      background-color: white;
      color: $darkGray;

      &--long {
        width: 100%;
      }

      &--error {
        border-color: $red;
      }

      &:disabled,
      &[disabled] {
        color: $gray;
        border-color: white;
      }
    }
  }
