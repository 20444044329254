/* ==========================================================================
   FORMS STYLES
   ========================================================================== */
@font-face {
  font-family: "Comfortaa Regular";
  src: url('../../assets/fonts/Comfortaa/Comfortaa-Regular.eot'),
  url('../../assets/fonts/Comfortaa/Comfortaa-Regular.woff') format('woff'),
  url('../../assets/fonts/Comfortaa/Comfortaa-Regular.ttf') format('truetype'),
  url('../../assets/fonts/Comfortaa/Comfortaa-Regular.svg') format('svg');
  font-style: normal;
  font-weight: normal;
}

  @font-face {
    font-family: "Comfortaa Bold";
  src: url('/assets/fonts/Comfortaa-Bold/Comfortaa-Bold.eot'),
  url('../../assets/fonts/Comfortaa-Bold/Comfortaa-Bold.woff') format('woff'),
  url('../../assets/fonts/Comfortaa-Bold/Comfortaa-Bold.ttf') format('truetype'),
  url('../../assets/fonts/Comfortaa-Bold/Comfortaa-Bold.svg') format('svg');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Comfortaa Light";
  src: url('../../assets/fonts/Comfortaa-Light/Comfortaa-Light.eot'),
  url('../../assets/fonts/Comfortaa-Light/Comfortaa-Light.woff') format('woff'),
  url('../../assets/fonts/Comfortaa-Light/Comfortaa-Light.ttf') format('truetype'),
  url('../../assets/fonts/Comfortaa-Light/Comfortaa-Light.svg') format('svg');
  font-style: normal;
  font-weight: normal;
}
