// ==========================================================================
// VARIABLES THEME
// ==========================================================================

// COLORS
// ------------------------------------
$blue: #01a4e0;
$lighterBlue: #44bfec;
$green: #22ba6e;
$purple: #6f4999;
$darkPurple: #582392;
$lightPurple: #8e75c0;
$red: #d62445;
$darkRed: #9d001e;
$lightRed: #ff5f70;
$lighterGray: #ededed;
$greyLight: #F0F2F3;
$darkBlue:  #0184CD;

$tooltipBackground: #A2D9F71F;
$tooltipBorder: #66C3D0;
$tooltipErrorBackground: #DE2C620F;
$tooltipErrorForeground: #de2c62;
$tooltipSuccessBackground: #34A8530F;
$tooltipSuccessForeground: #28BA72;
$tooltipBlockBackground: #D19F1F26;
$tooltipBlockForeground: #433560;
$tooltipBlockBorder: #E2B167;

// TEXT COLORS
// ------------------------------------
$mainColor: #9da0b3;
$lightGray: #d4d4d4;
$gray: #aaaab6;
$darkGray: #61616d;
$modalMainColor: #433560;
// FONTS
// ------------------------------------
$FBase: Arial, sans-serif;
$FOpenSans: 'OpenSans';
$FComfortaa: 'Comfortaa Regular';
$FComfortaaBold: 'Comfortaa Bold';
$FComfortaaLight: 'Comfortaa Light';

// USED FONTS
// ------------------------------------
$FDefault: $FComfortaa, $FOpenSans, $FBase;
