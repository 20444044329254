@import "./assets/font-awesome-4.7.0/scss/font-awesome.scss";
@import "./assets/fonts/fonts";
@import "variables";
@import "table-list";
@import "form";
@import "input";
@import "search";

* {
  font-family: $FDefault;
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
}

html {
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
  @media screen and (max-width: 420px) {
    font-size: 14px;
  }
}

.container {
  max-width: 1100px;
  padding: 0 15px;
  margin: 0 auto;

  &.fullBlock {
    max-width: none;
    padding: 0;
    margin: 0;
  }
}

img {
  max-width: 100%;
}

a {
  color: $blue;
}

.loader-wrap {
  width: 100%;
  height: 100%;
}

.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  animation: loader-circles 1s linear infinite;
  top: 50%;
  margin: -8px auto 0 auto;
}

body,
.modal {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d2d2d2;
    border: 2px solid #f1f1f1;
    border-radius: 8px;
  }
}

::-webkit-scrollbar {
  display: none;
}
