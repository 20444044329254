$title: #61616d;
$desc: #aaaab6;
$border: rgb(237, 237, 237);
$rowHover: rgb(250, 250, 250);

.table-list_wr {
  overflow: hidden;
}

.table-list {
  width: 100%;
  border-collapse: collapse;
}

.table-list__cell-wr {
  position: relative;
  padding: 14px 20px;
  min-height: 100px;
}

.table-list__item {
  vertical-align: top;
  transition: background-color 0.3s ease-out, transform 0.2s ease-in;
  cursor: pointer;
  transform: translateZ(0);
  backface-visibility: hidden;

  &::before {
    left: -100px;
  }

  &::after {
    right: -100px;
  }

  td {
    border-top: solid 2px $border;
    border-bottom: solid 2px $border;

    &:first-child,
    &:last-child {
      .table-list__cell-wr {
        &::before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          transition: background-color 0.3s ease-out, transform 0.2s ease-in;
          width: 200px;
          z-index: -1;
        }
      }
    }

    &:first-child {
      .table-list__cell-wr {
        &::before {
          left: -100px;
        }
      }
    }

    &:last-child {
      .table-list__cell-wr {
        &::before {
          right: -100px;
        }
      }
    }
  }

  &:first-child {
    td {
      border-top: 0;
    }
  }

  &:last-child {
    td {
      border-bottom: 0;
    }
  }

  &-img-cell {
    width: 100px;
  }

  &-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    position: relative;
  }

  &-img_bk {
    position: absolute;
    z-index: -1;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    left: -2px;
    top: -2px;
  }

  &-name {
    color: $title;
  }

  &-description {
    color: $desc;
  }

  &:hover {
    transform: scaleX(0.99);
    background-color: $rowHover;

    .table-list__cell-wr {
      &:before {
        background-color: $rowHover;
      }
    }
  }
}
