.search-box {
    position: relative;
    margin-bottom: 20px;
  
    &__description {
      font-size: 12px;
      margin-top: 8px;
      display: block;
    }
  
    &__list {
      position: absolute;
      left: 0;
      right: 0;
      top: 20px;
      padding: 10px 0 15px 0;
      z-index: 1000;
      background: #fff;
      border: 2px solid $gray;
      border-top: none;
    }
  
    &__list-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 15px;
      color: $darkGray;
      transition: ease, .5s;
  
      &:nth-child(2n-1) {
        background-color: $lighterBlue;
      }
  
      &:hover {
        background-color: darken($lighterBlue, 50%);
      }
    }
  }
  